import React from "react";
import { RampartAuthorized } from "../../rampart/RampartAuthorized";
import { NotFound } from "../NotFound";
import { AdminProductListPage } from "./AdminProductListPage";

import "./styles/ProductListPage.css";

export const ProductListPage = () => {
    return (
        <RampartAuthorized
            requiredGroups={["juniper.self-serve.admin"]}
            unauthorizedView={<NotFound/>}
        >
            <AdminProductListPage />
        </RampartAuthorized>
    );
}
