import {
    ArrowLeftIcon,
    ChatIcon,
    Heading,
    IconButton,
    InfoSignIcon,
    Pane,
    Table,
    Text,
    toaster,
    TrashIcon
} from "evergreen-ui";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import InfiniteScroll from "react-infinite-scroll-component";
import { SendMediaOptions } from "@twilio/conversations";
import {
    useActiveConversationAPIContext,
    useMessagingClientAPIContext,
    useMessagingDataContext
} from "../state/NativeMessagingProvider";
import { Tag } from "../../../../common/types";
import { ConversationSidebar } from "../conversation-sidebar";
import { EmptyState } from "../../common/EmptyState";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { ConversationViewTextInput } from "./ConversationViewTextInput";
import { MessagesList } from "../messages/MessagesList";
import { SendResult } from "../types";
import { ChatDescriptions, ChatType, NotificationCategory, NotificationType } from "../../../../common/enums";
import { registerConversationTags } from "../tagging/linkify-plugin-keyword";

import "./ConversationView.css"

export interface ConversationViewProps {
    isMobile?: boolean;
}

export const ConversationView = ({
    isMobile
}: ConversationViewProps) => {

    const {
        showListView,
        activeConversationSid,
        activeConversationError,
        activeConversationLoaded,
        activeConversationMessageStore,
        activeConversationPaginator,
        activeConversationDisabled,
        conversationStore,
        conversationTagManager
    } = useMessagingDataContext();

    const {
        sendMessage,
        fetchMessages,
        fetchMoreMessages,
        getParticipants,
    } = useActiveConversationAPIContext();

    const { updateActiveConversation } = useMessagingClientAPIContext();

    const [showSidebar, setShowSidebar] = useState<boolean>(false);
    const [initialMessagesLoaded, setInitialMessagesLoaded] = useState<boolean>(false);
    const [newMessagesLineIndex, setNewMessagesLineIndex] = useState<number | null>(null);
    const [sendMediaOptions, setSendMediaOptions] = useState<SendMediaOptions[]>([]);
    const scrollableDivRef = useRef<HTMLDivElement>(null);

    // We intentionally have an `undefined` initial value here because `null` is a valid SID value
    // that distinctly indicates the absence of an active conversation, whereas `undefined` should
    // also be distinctly considered as the uninitialized value.
    const activeConversationSidRef = useRef<string | null>();

    // This is used solely to identify whether we should load more messages so that
    // it's not weird to cut off messages halfway through the screen
    const isScrollableDivOverflown = (): boolean => {
        if (scrollableDivRef && scrollableDivRef.current) {
            let scrollableDiv = scrollableDivRef.current as HTMLDivElement;
            return scrollableDiv.scrollHeight > scrollableDiv.clientHeight || scrollableDiv.scrollWidth > scrollableDiv.clientWidth;
        }
        return false;
    }

    useEffect(() => {
        if (conversationTagManager) {
            conversationTagManager.getAllPossibleTags().then((tags: Tag[]) => {
                registerConversationTags(tags.map((tag: Tag) => tag.data.displayName));
            });
        }
    }, [conversationTagManager]);

    useEffect(() => {
        let mounted = true;
        // Only update state when the active conversation actually changes. This is
        // so that we ensure that we don't erase the "New Message" line after reading the
        // new messages, which relies on the `initialLastReadMessageIndex` state not updating
        // after being set initially.
        if (activeConversationSidRef.current === activeConversationSid) {
            return;
        }
        setInitialMessagesLoaded(false);
        activeConversationSidRef.current = activeConversationSid;
        const selectedConversationMetadata = activeConversationSid ? conversationStore[activeConversationSid] : null;
        if (selectedConversationMetadata && selectedConversationMetadata.unreadMessageCount && selectedConversationMetadata.lastMessage) {
            setNewMessagesLineIndex(selectedConversationMetadata.lastReadMessageIndex);
        }
        (async () => {
            // Component may be unmounted by the time this async function runs, so we need to ensure not
            // to continue through if that is the case.
            if (!mounted) {
                return;
            }

            if (!activeConversationSid) {
                // Set to true since there's no active conversation to load messages for.
                setInitialMessagesLoaded(true);
                return;
            }

            await fetchMessages();

            // Component may be unmounted after we asynchronously fetch participants. We need
            // to not set state if that is the case.
            if (!mounted) {
                return;
            }

            // If it's not overflown, lets grab some more messages
            //
            // Skip this for mobile as it leads to weird snapping on iOS
            if (!isMobile && !isScrollableDivOverflown()) {
                fetchMoreMessages();
            }

            setInitialMessagesLoaded(true);
        })();
        return () => {
            mounted = false;
            setNewMessagesLineIndex(null);
            setInitialMessagesLoaded(false);
            setSendMediaOptions([]);
        };
    }, [activeConversationSid]);

    const goBackToListView = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        updateActiveConversation(undefined);  // Clear the active conversation.
    };

    const snapScrollableDivToBottom = useCallback((): void => {
        if (scrollableDivRef && scrollableDivRef.current) {
            (scrollableDivRef.current as HTMLDivElement).scrollTop = (scrollableDivRef.current as HTMLDivElement).scrollHeight;
        }
    }, []);

    const onTextInputEnter = async (text: string, tags?: Tag[]): Promise<SendResult> => {
        if (!text && sendMediaOptions.length < 1) {
            return Promise.resolve(SendResult.ignored);
        }

        const sendResult = await sendMessage(text, sendMediaOptions, {
            tags: tags,
            notificationCategory: NotificationCategory.ConversationMessage,
            notificationType: NotificationType.ChatMessage
        });

        if (sendResult === SendResult.success) {
            setSendMediaOptions([]);
            snapScrollableDivToBottom();
        }

        return Promise.resolve(sendResult);
    };

    const removeFile = (removeIdx: number) => {
        setSendMediaOptions(sendMediaOptions.filter((_, index) => index !== removeIdx))
    }

    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({ noClick: true });

    useEffect(() => {
        const MAX_FILES = 10;
        const FILES_REMAINING = MAX_FILES - sendMediaOptions.length;
        if (acceptedFiles.length > FILES_REMAINING) {
            toaster.warning("You've reached the max number of attachments (10) per message. Please send the rest in a separate message.");
        }

        acceptedFiles.slice(0, FILES_REMAINING).forEach((newFile: File) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('File Reading was aborted')
            reader.onerror = () => console.log('File Reading has failed')
            reader.onload = () => {
                if (reader.result) {
                    let blob = new Blob([reader.result], { type: newFile.type });
                    const mediaOption: SendMediaOptions = {
                        contentType: newFile.type || "application/octet-stream",
                        filename: newFile.name,
                        media: blob
                    }
                    setSendMediaOptions((sendMediaOptions) => [...sendMediaOptions, mediaOption]);
                }
            }
            reader.readAsArrayBuffer(newFile);
        });
    }, [acceptedFiles])

    useLayoutEffect(() => {
        window.addEventListener("resize", snapScrollableDivToBottom);
        return () => window.removeEventListener("resize", snapScrollableDivToBottom);
    }, [snapScrollableDivToBottom]);

    const showLoadingState = (): boolean => {
        return !activeConversationLoaded ||
            (!activeConversationError && !initialMessagesLoaded);
    };

    const getConversationDescription = () => {
        return activeConversationSid ? ChatDescriptions[conversationStore[activeConversationSid]?.type as ChatType] : "";
    };

    const getConversationTitle = () => {
        return activeConversationSid ? `${activeConversationDisabled ? "[CANCELLED] " : ""} ${conversationStore[activeConversationSid]?.friendlyName}` : "";
    };

    return (
        <>
            <div
                style={{
                    padding: "0",
                    width: "auto",
                    height: "100%",
                    display: showListView && isMobile ? "none" : "flex",
                    overflow: "hidden",
                    flexDirection: "column"
                }}
                {...getRootProps({
                    onClick: event => event.stopPropagation()
                })}
            >
                <Table style={{ borderBottom: 0, borderRadius: 0, borderLeft: 0 }}>
                    <Table.Head height={"56px"} onClick={() => isMobile && setShowSidebar(true)}>
                        {isMobile && (
                            <Table.HeaderCell height={"56px"} maxWidth={"56px"}>
                                <IconButton
                                    appearance={"minimal"}
                                    iconSize={20}
                                    icon={ArrowLeftIcon}
                                    onClick={goBackToListView}
                                />
                            </Table.HeaderCell>
                        )}
                        {(!showLoadingState() && activeConversationSid !== null) && (
                            <>
                                <Table.TextHeaderCell className={"fade-in"} paddingLeft={isMobile ? 0 : 12}>
                                    <Heading className={"conversation-title"} fontSize={14}>
                                        {getConversationTitle()}
                                    </Heading>
                                    {
                                        conversationStore[activeConversationSid]?.type !== undefined &&
                                        <Text className={"conversation-description"} fontSize={11}>
                                            {getConversationDescription()}
                                        </Text>
                                    }
                                </Table.TextHeaderCell>
                                <Table.HeaderCell
                                    height={"56px"}
                                    maxWidth={"56px"}
                                    className={"fade-in"}
                                >
                                    <IconButton
                                        appearance={"minimal"}
                                        iconSize={16}
                                        icon={InfoSignIcon}
                                        onClick={() => setShowSidebar(true)}
                                    />
                                </Table.HeaderCell>
                            </>
                        )}
                    </Table.Head>
                </Table>
                {showLoadingState() ? (
                    <CenteredSpinner />
                ) : (activeConversationError || activeConversationSid === null) ? (
                    <EmptyState
                        className={"fade-in"}
                        title={"Messages"}
                        subtitle={activeConversationError ?
                            "Conversation not found." :
                            "Start by selecting a conversation from the list to the left."
                        }
                    >
                        <ChatIcon size={60} />
                    </EmptyState>
                ) : (
                    <>
                        <Pane
                            id="scrollableDiv"
                            className={"fade-in"}
                            ref={scrollableDivRef}
                            style={{
                                background: "white",
                                overflow: "auto",
                                display: "flex",
                                padding: "12px",
                                height: "auto",
                                flexDirection: "column-reverse"
                            }}
                        >
                            <InfiniteScroll
                                dataLength={activeConversationMessageStore.length}
                                next={fetchMoreMessages}
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    paddingTop: "12px"
                                }}
                                inverse={true}
                                hasMore={activeConversationPaginator?.hasPrevPage ?? false}
                                loader={
                                    // Hack to get the CenteredSpinner to load without causing
                                    // scroll issues - needs to be surrounded by <ul> element
                                    <ul>
                                        <CenteredSpinner size={16} />
                                    </ul>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                <MessagesList
                                    participants={getParticipants()}
                                    newMessagesLineIndex={newMessagesLineIndex}
                                />
                            </InfiniteScroll>
                        </Pane>
                        <Pane marginTop="auto" className={"fade-in"}>
                            <ConversationViewTextInput
                                isDisabled={true /* As of Q1 2025, chat is read-only. */}
                                isMobile={isMobile}
                                isDragActive={isDragActive}
                                getDragInputProps={getInputProps}
                                openDialog={open}
                                onSubmit={onTextInputEnter}
                            />
                            <Pane paddingBottom={"5px"} paddingX={"20px"}>
                                {sendMediaOptions.map((mediaOption, index) => {
                                    return (
                                        <div key={index} className="file-name">
                                            <span> {mediaOption.filename} </span>
                                            <span> {Math.round((mediaOption!.media as Blob).size / 1000)} KB </span>
                                            <IconButton icon={TrashIcon} intent="danger" appearance="minimal" onClick={() => removeFile(index)} />
                                        </div>
                                    )
                                })}
                            </Pane>
                        </Pane>
                    </>
                )}
            </div>
            <ConversationSidebar
                isShown={showSidebar}
                conversationDescription={getConversationDescription()}
                conversationTitle={getConversationTitle()}
                conversationSid={activeConversationSid ?? ""}
                onClose={() => setShowSidebar(false)}
                participants={getParticipants()}
                isLoadingViewer={showLoadingState()}
            />
        </>
    );
};
