import React from "react";
import { RampartAuthorized } from "../rampart/RampartAuthorized";
import { NativeMessages } from "./NativeMessages";
import { EmptyState } from "../common/EmptyState";
import { ChatIcon } from "evergreen-ui";

export const AdminOnlyMessages = () => {
    return (
        <RampartAuthorized
            requiredGroups={["juniper.self-serve.admin"]}
            unauthorizedView={(
                <EmptyState
                    title={"Messages are no longer supported."}
                    subtitle={"Email us at client-success@hellojuniper.com."}
                >
                    <ChatIcon size={60}/>
                </EmptyState>
            )}
        >
            <NativeMessages />
        </RampartAuthorized>
    )
}
