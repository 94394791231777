import { ForwardRefExoticComponent } from "react";
import {
    ChatIcon,
    GroupedBarChartIcon,
    LogOutIcon,
    NewGridItemIcon,
    PersonIcon,
    ShopIcon,
    CreditCardIcon
} from "evergreen-ui";

export type NavigationTab = {
    name: string,
    to?: string,
    icon: ForwardRefExoticComponent<any>,
    showNewBadge?: boolean,
};

export const tabs: {top: NavigationTab[], bottom: NavigationTab[]} = {
    top: [
        {
            name: "Products",
            to: "/products",
            icon: NewGridItemIcon,
        },
        {
            name: "Messages",
            to: "/messages",
            icon: ChatIcon,
        },
        {
            name: "Analytics",
            to: "/analytics",
            icon: GroupedBarChartIcon,
        },
        {
            name: "Payouts",
            to: "/payouts",
            icon: CreditCardIcon,
        },
        {
            name: "Stores",
            to: "/stores",
            icon: ShopIcon,
        }
    ],
    bottom: [
        {
            name: "Settings",
            to: "/settings",
            icon: PersonIcon,
        },
        {
            name: "Logout",
            to: "/logout",
            icon: LogOutIcon,
        }
    ],
};
